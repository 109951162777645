<template>
    <b-card-code
        title="Add New Package Info"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            label="Package Name"
                            label-for="package-name">
                            <validation-provider
                                #default="{ errors }"
                                name="Package Name"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="package_name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Package Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Bandwidth"
                            label-for="bandwidth">
                            <validation-provider
                                #default="{ errors }"
                                name="Bandwidth"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="bandwidth"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Bandwidth"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Validity"
                            label-for="validity">
                            <validation-provider
                                #default="{ errors }"
                                name="Validity"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="validity"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Validity"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Price"
                            label-for="price">
                            <validation-provider
                                #default="{ errors }"
                                name="Price"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="price"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Package Price"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <validation-provider
                                #default="{ errors }"
                                name="Status"
                                rules="required"
                            >
                                <b-form-select
                                    v-model="selected"
                                    :options="status"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            type="submit"
                            variant="primary"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormSelect,
} from 'bootstrap-vue'
import {required, email} from '@validations'
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Mixin from "@core/services/mixin";
import {checkRes} from "@core/services/helper";
import store from '@/store'
import $ from "jquery";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            package_name: null,
            bandwidth: null,
            validity: null,
            price: null,
            selected: null,
            status: [
                {value: null, text: 'Please select status'},
                {value: '1', text: 'Active'},
                {value: '0', text: 'Inactive'}
            ],
            emailValue: '',
            name: '',
            required,
            email,
            isp_id:null,
        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.createPackage({
                        isp_id:this.getIspId(),
                        package_name: this.package_name,
                        bandwidth: this.bandwidth,
                        validity: this.validity,
                        price: this.price,
                        status: this.selected,
                    }).then(response => {
                        if (checkRes(response.data.code)) {
                            this.$refs.simpleRules.reset()
                            this.package_name=''
                            this.bandwidth=''
                            this.validity=''
                            this.price=''
                            this.$router.push({name: 'isp-package-create'})
                                .catch(error => {
                                    this.toastMessage('success','Package Setting', response)
                                })
                        } else {
                            this.$router.push({name: 'isp-package-create'})
                                .catch(error => {
                                    this.toastMessage('warning','Package Setting', response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        getIspId() {
      return $("#ispListId option:selected").val();  
    },
    }, 
    mounted() {
      this.getIspId() 
    }
}
</script>
